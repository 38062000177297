<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row class="justify-content-md-center">
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Office</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="officeFilter"
            :options="officeOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:officeFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Category</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="categoryFilter"
            :options="categoryOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:categoryFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Service</label>
          {{ $store.state.appConfig.isRTL }}
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="serviceFilter"
            :options="serviceOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:serviceFilter', val)"
          />
        </b-col>
      </b-row>
      <b-row class="justify-content-md-center">
        <!-- datepicker -->
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <div>
            <label>Range Date</label>
            <div class="d-flex align-items-center">
              <feather-icon icon="CalendarIcon" size="20" />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range' }"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
                @input="(val) => $emit('update:rangeDateFilter', val)"
              />
              <feather-icon icon="RefreshCwIcon" size="20" @click="reset" />
            </div>
          </div>
        </b-col>
        <!-- datepicker -->
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,

    flatPickr,
  },
  props: {
    rangeDateFilter: {
      type: [String, null],
      default: null,
    },
    officeFilter: {
      type: [String, Number, null],
      default: null,
    },
    officeOptions: {
      type: Array,
      required: false,
    },
    categoryFilter: {
      type: [String, Number, null],
      default: null,
    },
    categoryOptions: {
      type: Array,
      required: false,
    },
    serviceFilter: {
      type: [String, Number, null],
      default: null,
    },
    serviceOptions: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      rangePicker: [],
      reset: () => {
        this.rangePicker = [];
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
